import React, { useState } from "react";
import Card from "./card";

const CardPage = () => {
  const [content, setContent] = useState(0);
  const data = [
    {
      head: "Meme Marketing",
      desc: "Get ready to embark on a thrilling journey that transforms your online presence with our engaging, entertaining, and exhilarating digital marketing campaigns!",
      color1: "card-black",
    },
    {
      head: "Branding & Marketing",
      desc: "Yeh social media hai, yahaan sab kuch hota hai, zaroorat hai toh bas strategy ki!",
      color1: "card-red",
    },
    {
      head: "Media Planning & Buying",
      desc: "Hire security because you are about to get viral with our carefully crafted meme-tastic campaigns.",
      color1: "card-green",
    },
    {
      head: "Creative Strategies & consultancy",
      desc: "Pushpa, we hate Mundane Let's cook up a unique recipe for your brand's social success. One-size-doesn't-fits-all, that's why we add creative spices crafted just for you.",
      color1: "card-black",
    },
    {
      head: "Viral Marketing",
      desc: "Making you the talk of the town through carefully crafted campaigns.",
      color1: "card-red",
    },
    {
      head: "Meme Marketing",
      desc: "Virality abhi baaki hai mere dost Hire security because you are about to get viral with our carefully crafted meme-tastic campaigns.",
      color1: "card-black",
    },
    {
      head: "Branding & Marketing",
      desc: "Rahul? Naam toh suna hoga In a world full of Rahuls, be You. Stand out from the crowd with our services which empowers your brand by giving it a unique identity/voice.",
      color1: "card-red",
    },
    {
      head: "Media Planning & Buying",
      desc: "Kitne options they? Cut through the clutter with our aided expertise. Re-define, plan and deploy your marketing goals.",
      color1: "card-green",
    },
    {
      head: "Creative Strategies & consultancy",
      desc: "Pushpa, we hate Mundane Let's cook up a unique recipe for your brand's social success. One-size-doesn't-fits-all, that's why we add creative spices crafted just for you.",
      color1: "card-black",
    },
    {
      head: "Viral Marketing",
      desc: "Making you the talk of the town through carefully crafted campaigns.",
      color1: "card-red",
    },
  ];

  const data2 = [
    {
      key: 0,
      head: "Digital Marketing",
      desc: "Get ready to embark on a thrilling journey that transforms your online presence with our engaging, entertaining, and exhilarating digital marketing campaigns!",
      color1: "card-black",
      subpart: [
        {
          subheading: "SEO Strategies to Rocket Up the Search Rankings:",
          desc: [
            "On-page optimization to please search engine gods",
            " Off-page optimization for building authority and trust ",
            "Technical SEO to ensure smooth sailing",
            " Keyword research to uncover hidden gems",
            " Content optimization that hits the sweet spot",
          ],
        },

        {
          subheading: " SMO Techniques to Supercharge Your Online Presence:",
          desc: [
            "Enhance your brand's visibility on social platforms",
            "Optimize social profiles for maximum impact",
            "Leverage social media for customer engagement",
          ],
        },
        {
          subheading: " High-Impact Ads Across Platforms:",
          desc: [
            " Google and Bing Ads to drive traffic and conversions",
            " Social media ads on Facebook, Instagram, and LinkedIn for buzz and engagement",
            "Display advertising that grabs attention",
          ],
        },

        {
          subheading: "Email Marketing Campaigns that Captivate and Convert:",
          desc: [
            "Email strategies that keep subscribers engaged",
            " Stunning campaign designs for every inbox",
            " List management and segmentation for organization",
            " Marketing automation that simplifies life",
          ],
        },
        {
          subheading: "PPC Advertising Thrills",
          desc: [
            "Pay-Per-Click campaigns that drive traffic and conversions",
            "Strategically targeted ads to reach your audience",
            "Optimized bidding strategies for ad performance",
          ],
        },
      ],
    },
    {
      key: 1,
      head: "Social Media Management & Marketing",
      desc: "Yeh social media hai, yahaan sab kuch hota hai, zaroorat hai toh bas strategy ki!",
      color1: "card-red",
      subpart: [
        {
          subheading: "Content Creation and Curation that Captivates:",
          desc: [
            "Witty copywriting that sparks lively conversations",
            "Engaging blog posts that keep readers coming back for more",
            "Eye-catching videos and photography that showcase your brand's story",
            "Informative podcasts, infographics, and illustrations that simplify complexity",
            "Graphic design that makes your brand pop across print materials and digital",
            "assets",
          ],
        },
        {
          subheading: "Expert Campaign Management and Tailored Strategies:",
          desc: [
            " Social media strategies that attract a loyal following",
            " Content planning that keeps your feeds fresh and engaging",
            " Hassle-free social media management and posting",
            " Social media ads that connect and convert",
          ],
        },
        {
          subheading: "Engagement Boosts and Analytics Insights:",
          desc: [
            " Amplify your reach with targeted engagement strategies",
            " Analytics and reporting that provides a clear picture of your success",
            " Data-driven decision-making for continuous improvement",
          ],
        },
        {
          subheading: "Bespoke Consultancy and Strategic Planning:",
          desc: [
            " Tailored social media consultancy services for your unique needs",
            " Smart social media planning and buying for targeted success",
          ],
        },
        {
          subheading: "PPC Advertising Thrills",
          desc: [
            "Pay-Per-Click campaigns that drive traffic and conversions",
            "Strategically targeted ads to reach your audience",
            "Optimized bidding strategies for ad performance",
          ],
        },
      ],
    },
    {
      key: 2,
      head: "Meme Marketing",
      desc: "Virality abhi baaki hai mere dost Hire security because you are about to get viral with our carefully crafted meme-tastic campaigns.",
      color1: "card-green",
      subpart: [
        " Craft viral memes that capture hearts and minds",
        " Unleash contagious campaigns that spread like wildfire",
        " Keep the buzz going with strategic follow-up campaigns",
        " Design engaging experiences that capture hearts and minds",
        " Leverage social media and influencers to amplify your message",
        " Measure and analyze the impact of your viral sensations",
        " Data-driven decision-making for continuous improvement",
      ],
    },
    {
      key: 3,
      head: " Influencer & Celebrity Marketing:-",
      desc: "With all the buzz being online, work with us to identify and collaborate with the most influential voices in your calling. We don't target a mass audience, we mass target the niche audience.",
      color1: "card-black",
      subpart: [
        "Partner with influencers and celebrities that elevate your brand",
        "Identify the perfect influencers and celebrities to resonate with your target",
        "audience",
        "Develop impactful collaborations and campaigns that drive results",
        "Strategize and execute high-impact campaigns",
        "Monitor and measure the success of your partnerships",
        "Unleash the power of share-worthy content that gets people talking",
        "Design engaging experiences that capture hearts and minds",
        "Craft virulent campaigns that spread like wildfire",
      ],
    },
    {
      key: 4,
      head: " Web Design & Development",
      desc: "Cut through the clutter with our aided expertise. Re-define, plan and deploy your marketing goals.",
      color1: "card-red",
      subpart: [
        " Responsive websites that look amazing on any device",
        " UX design that makes your users feel right at home",
        " UI design that turns every click into a delightful experience",
        " E-commerce websites that transform shopping into a pleasure",
        " Content management systems (CMS) integration that lets you take the reins",
      ],
    },
    {
      key: 5,
      head: " Branding & Marketing",
      desc: "In a world full of Rahuls, be You. Stand out from the crowd with our services which empower your brand by giving it a unique identity/voice.",
      color1: "card-green",
      subpart: [
        " Let's design an unforgettable logo together",
        " Whip up some brand guidelines that'll guide your story",
        " Create brand messages that resonate with your audience",
        " Shape a visual identity that'll turn heads",
      ],
    },
  ];
  const ref = React.useRef();

  React.useEffect(() => {
    const card = document.querySelectorAll("#card111");
    card.forEach((c) => {
      c.classList.add("animate-downToTop4");
    });
  }, [content]);

  return (
    <div
      className=" flex flex-col md:flex-row px-4 md:pl-8 lg:pl-12 lg:-mt-24 md:h-screen md:w-screen overflow-hidden "
      data-aos="fade-up"
    >
      {/* //text */}
      <div className="md:w-half">
        <div className="flex flex-col w-full h-full gap-6 overflow-scroll no-scrollbar transition-all ease-in  py-6">
          {/* isko map karne ke liye alag array banega */}
          {content !== null &&
            data2[content].subpart.map((i) => (
              <div
                className="rounded-3xl w-5/6 lg:w-4/6  px-2 py-2  md:px-6 lg:px-8 xl:py-4 md:py-2 border-2 card-shadow border-black  "
                id="card111"
                onAnimationEnd={(e) => {
                  e.target.classList.remove("animate-downToTop4");
                }}
              >
                <div
                  className="flex flex-row justify-between transition-all duration-1000 "
                  // onClick={() => {
                  //   if (content === i.desc) {
                  //     setContent("");
                  //   } else setContent(i.desc);
                  // }}
                  ref={ref}
                >
                  {i.subheading ? (
                    <div className=" font-bebas text-base md:text-xl">{i.subheading}</div>
                  ) : (
                    <div className=" font-bebas text-xl">{i}</div>
                  )}
                  <div className="">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6 stroke-2"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M8.25 4.5l7.5 7.5-7.5 7.5"
                      />
                    </svg>
                  </div>
                </div>
                {/* <div className={`${"block"}  `}>
                {i.subpart.map((j) => <div>{j.desc.map((k) => (
                  <div>
                    {k}
                  </div>
                ))}</div>)}
              </div> */}
              </div>
            ))}
        </div>
      </div>
      {/* //cards */}
      <div className="h-[90vh] md:w-half  overflow-scroll md:p-8 pb-16 no-scrollbar gap-10  lg:mt-0 mb-24 flex flex-col items-center">
        {data2.map((i) => (
          <div
            className={`p-8 md:h-max md:w-[38vw] flex flex-none flex-col justify-between bg-${i.color1} text-white rounded-xl`}
            // style={{backgroundColor: data.color}}
            onMouseEnter={() => {
              setContent(i.key);
            }}
          >
            <div className="bg-card-red bg-card-black bg-card-green bg-[#65C9FF]  bg-[#FEED01] hidden"></div>
            <div className="flex gap-5 flex-col">
              <div className="">
                <span className="uppercase text-3xl md:text-4xl font-dm ">
                  {i.head}
                </span>
              </div>
              <div className="lg:text-md text-sm  font-dm  uppercase leading-8">
                {i.desc}
              </div>
            </div>
            <div className="h-12 w-12 rounded-full bg-black/90 items-center flex justify-center cursor-pointer">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="w-6 h-6 text-white "
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardPage;
