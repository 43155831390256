import React from "react";
import Navbar from "./navbar";
import Plane from "./plane";
const PlanePage = () => {
  return (
    <div className="w-screen  relative overflow-hidden flex flex-col">
      <Navbar />
      <div className="h-2/5 w-2/5 absolute blur-[500px] rounded-full bg-[#CD2020] -top-1/4 -right-1/4 z-10"></div>
      {/* Image */}

      <div className="w-full h-[60vh] md:h-[80vh] flex justify-center items-center bg-black relative">
        <div
          className="absolute  top-[20%] md:top-1/4 right-1/3 w-1/2 h-1/2"
          data-aos="fade-up"
        >
          <svg
            width="679"
            height="442"
            viewBox="0 0 679 442"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="h-full w-full"
          >
            <path
              d="M273.279 119.437C273.279 119.437 224.33 126.667 188.608 153.828C188.608 153.828 205.293 148.16 224.724 141.443C224.724 141.443 189.868 156.996 171.895 174.481C171.895 174.481 193.235 162.701 207.168 161.461C207.168 161.461 178.855 177.027 164.445 207.394C164.445 207.394 187.073 184.219 209.463 175.396C209.463 175.396 199.108 182.342 193.164 200.271C193.164 200.271 207.974 181.303 232.056 170.795C232.056 170.795 220.007 180.693 219.346 195.255C219.346 195.255 234.792 175.233 267.959 159.465C301.337 144.12 277.982 120.189 277.982 120.189L273.279 119.437Z"
              fill="#E97885"
              stroke="#17204E"
              stroke-width="1.30862"
              stroke-miterlimit="10"
            />
            <path
              d="M248.419 122.014C248.419 122.014 257.966 116.217 272.855 114.385C287.976 112.553 300.065 115.366 303.764 127.468C307.462 139.802 303.954 149.797 281.379 157.197C258.805 164.364 256.942 165.524 256.942 165.524C256.942 165.524 257.653 158.548 268.829 152.289C268.829 152.289 246.966 152.248 229.737 161.519C229.737 161.519 240.924 149.213 257.922 139.243C257.922 139.243 243.046 134.098 230.245 138.726C230.245 138.726 250.504 126.205 262.371 123.668C262.138 123.901 257.026 120.867 248.419 122.014Z"
              fill="#FFD6D6"
              stroke="#17204E"
              stroke-width="1.44203"
              stroke-miterlimit="10"
            />
            <path
              d="M290.955 118.783C290.955 118.783 324.168 89.4239 371.338 88.3454C418.507 87.2668 437.167 98.2767 437.167 98.2767C437.167 98.2767 414.91 137.697 368.889 148.818C323.101 159.94 296.973 147.048 296.973 147.048C296.973 147.048 282.283 136.046 290.955 118.783Z"
              fill="#B3B8FD"
              stroke="#17204E"
              stroke-width="2.05482"
              stroke-miterlimit="10"
            />
            <path
              d="M367.827 87.8659C367.827 87.8659 367.859 70.8203 355.507 58.4213C343.155 46.0224 328.904 49.7315 317.448 57.182C305.291 65.0982 294.977 86.094 317.118 108.552C339.492 131.01 348.852 120.287 350.964 114.687C353.31 109.087 356.616 89.2458 367.827 87.8659Z"
              fill="#FCFF83"
            />
            <path
              d="M355.482 69.166C355.482 69.166 349.899 58.1808 344.062 57.7028C338.225 57.4583 340.544 66.1023 345.906 70.3154C351.269 74.7621 358.04 75.0084 355.482 69.166Z"
              fill="#B2F6DD"
              stroke="#17204E"
              stroke-width="1.44772"
              stroke-miterlimit="10"
            />
            <path
              d="M343.095 80.1178C343.095 80.1178 337.515 67.2647 330.976 67.7193C324.437 68.174 325.59 75.6483 327.92 78.2212C330.251 80.7941 336.775 88.045 340.749 85.7175C344.489 83.623 343.095 80.1178 343.095 80.1178Z"
              fill="#B2F6DD"
              stroke="#17204E"
              stroke-width="1.44772"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M414.065 91.2267C414.065 91.2267 413.606 87.2563 408.238 85.8452C402.87 84.434 401.001 85.131 398.911 79.056C396.821 72.981 384.448 71.5566 382.332 79.0247C380.216 86.4928 367.841 86.236 363.379 99.7707C358.917 113.539 358.213 115.172 368.014 118.46C378.048 121.981 388.812 109.859 396.512 112.676C404.212 115.492 407.014 115.731 407.014 115.731"
              stroke="#E97885"
              stroke-width="1.44772"
              stroke-miterlimit="10"
            />
            <path
              d="M334.648 99.485C334.648 99.485 336.484 116.768 360.08 110.041C360.08 110.041 363.117 109.579 363.119 108.178C363.356 106.778 361.955 106.775 361.955 106.775"
              stroke="#FCFF83"
              stroke-width="1.44772"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M326.239 103.437C326.239 103.437 310.578 112.047 330.141 139.403C349.704 166.76 365.849 149.044 365.849 149.044"
              stroke="#FCFF83"
              stroke-width="1.44772"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M318.539 102.251C318.539 102.251 306.38 111.334 317.081 132.603C327.782 153.872 356.498 156.495 373.56 147.42"
              stroke="#FCFF83"
              stroke-width="1.44772"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M312.244 98.5069C312.244 98.5069 292.844 108.511 300.748 127.206"
              stroke="#FCFF83"
              stroke-width="1.44772"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M336.814 70.5322C336.814 70.5322 333.781 69.3589 333.309 71.9266C332.837 74.4942 335.624 82.2051 341.457 84.7846C341.457 84.7846 343.794 83.6215 343.332 81.0521C342.636 78.7158 337.513 71.0005 336.814 70.5322Z"
              fill="#17204E"
              stroke="#17204E"
              stroke-width="1.44772"
              stroke-miterlimit="10"
            />
            <path
              d="M348.494 60.2784C348.494 60.2784 347.095 59.1082 346.159 60.5075C345.222 61.9067 345.45 64.9427 347.779 68.2161C350.108 71.4896 352.904 74.2969 354.774 73.3664C356.644 72.4359 355.949 69.3991 354.319 67.2945C352.922 64.9568 348.494 60.2784 348.494 60.2784Z"
              fill="#17204E"
              stroke="#17204E"
              stroke-width="1.44772"
              stroke-miterlimit="10"
            />
            <path
              d="M365.988 75.0263C365.988 75.0263 374.631 73.1745 380.458 78.5561C380.458 78.5561 381.857 79.9597 382.321 81.3616C383.018 82.9975 381.385 82.2939 381.385 82.2939"
              stroke="#FCFF83"
              stroke-width="1.44772"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M368.765 87.4021C371.351 77.8333 370.905 67.0914 365.318 57.9742C354.61 40.4413 331.737 34.7941 314.204 45.5022C296.671 56.2102 291.024 79.0829 301.732 96.6158C312.44 114.149 334.378 120.261 351.911 109.553"
              stroke="#B2F6DD"
              stroke-width="1.44772"
              stroke-miterlimit="10"
              stroke-linecap="round"
            />
            <path
              d="M397.948 87.9292C391.875 88.8518 386.745 85.3396 386.522 79.9686C386.065 74.5971 390.744 69.7024 396.817 68.7799C402.89 67.8573 408.02 71.3695 408.243 76.7405C408.467 81.878 404.021 87.0067 397.948 87.9292Z"
              fill="#46C9C1"
            />
            <path
              d="M392.144 73.9023C391.677 73.4344 391.211 72.9665 390.978 72.9661C391.914 72.0338 392.848 71.5686 392.848 71.5686C394.016 71.5708 394.248 72.5052 394.248 72.5052C393.546 72.9709 392.845 73.4366 392.144 73.9023Z"
              fill="#B2F6DD"
            />
            <path
              d="M389.101 76.7027C389.336 75.5356 390.039 74.6029 390.74 73.9037C391.207 74.1381 391.674 74.3725 392.14 74.6069C389.332 77.8706 389.558 81.6071 389.558 81.6071C389.092 81.3727 388.395 79.2699 389.101 76.7027Z"
              fill="#B2F6DD"
            />
            <path
              d="M389.995 100.051C383.922 100.974 378.792 97.4616 378.568 92.0906C378.112 86.7192 382.791 81.8245 388.864 80.9019C394.936 79.9794 400.067 83.4916 400.29 88.8626C400.514 94.0001 395.834 99.1283 389.995 100.051Z"
              fill="#FCFF83"
            />
            <path
              d="M384.172 86.0335C383.706 85.5656 383.24 85.0977 383.006 85.0972C383.942 84.165 384.877 83.6998 384.877 83.6998C386.044 83.702 386.276 84.6364 386.276 84.6364C385.575 85.1021 384.64 85.5673 384.172 86.0335Z"
              fill="#B2F6DD"
            />
            <path
              d="M381.148 88.8247C381.383 87.6577 382.085 86.725 382.787 86.0258C383.254 86.2602 383.72 86.4946 384.187 86.729C381.379 89.9927 381.605 93.7292 381.605 93.7292C381.139 93.4948 380.442 91.6254 381.148 88.8247Z"
              fill="#B2F6DD"
            />
            <path
              d="M405.866 97.0504C399.793 97.9729 394.663 94.4607 394.439 89.0897C393.983 83.7183 398.662 78.8236 404.735 77.901C410.808 76.9785 415.938 80.4907 416.161 85.8617C416.385 91.2327 411.939 96.1278 405.866 97.0504Z"
              fill="#E97885"
            />
            <path
              d="M400.061 83.2606C399.594 82.7927 399.128 82.3249 398.895 82.3244C399.831 81.3922 400.765 80.9269 400.765 80.9269C401.933 80.9291 402.165 81.8636 402.165 81.8636C401.464 82.0958 400.762 82.5614 400.061 83.2606Z"
              fill="#FFD6D6"
            />
            <path
              d="M397.014 86.0518C397.25 84.8848 397.952 83.9521 398.654 83.2529C399.121 83.4873 399.587 83.7217 400.054 83.956C397.246 87.2198 397.472 90.9563 397.472 90.9563C397.24 90.4888 396.309 88.619 397.014 86.0518Z"
              fill="#FFD6D6"
            />
            <path
              d="M649.644 44.4372C661.086 44.4372 670.426 35.0971 670.426 23.6554C670.426 12.2138 661.086 2.87372 649.644 2.87372C638.202 2.87372 628.862 12.2138 628.862 23.6554C628.862 35.0971 638.202 44.4372 649.644 44.4372Z"
              fill="#573FEB"
            />
            <path
              d="M670.67 23.6491C670.67 23.6491 677.908 24.5831 677.675 33.2227C677.441 42.0958 656.893 42.0958 643.35 34.1567C629.807 26.4511 615.096 27.3851 617.898 18.045C620.467 8.70495 631.908 12.441 631.908 12.441"
              stroke="#FBFCEC"
              stroke-width="1.44772"
              stroke-miterlimit="10"
            />
            <path
              d="M641.949 12.2083C641.249 10.8073 640.548 10.1068 640.081 9.40631C642.183 8.23879 644.051 7.77179 644.051 7.77179C646.386 8.4723 646.386 10.3403 646.386 10.3403C644.751 10.8073 643.35 11.5078 641.949 12.2083Z"
              fill="#B3B8FD"
            />
            <path
              d="M634.472 15.9498C635.406 13.8483 636.807 12.2138 638.208 11.0463C638.909 11.9803 639.609 12.9143 640.31 13.6148C634.472 18.5183 633.538 26.9244 633.538 26.9244C633.071 25.5234 632.371 20.8534 634.472 15.9498Z"
              fill="#B3B8FD"
            />
            <path
              d="M198.287 21.3167C204.124 21.3167 208.795 16.6467 208.795 10.8091C208.795 4.97156 204.124 0.301514 198.287 0.301514C192.449 0.301514 187.779 4.97156 187.779 10.8091C187.779 16.6467 192.683 21.3167 198.287 21.3167Z"
              fill="#BDEEED"
            />
            <path
              d="M106.054 51.6721C110.023 51.6721 113.059 48.6365 113.059 44.667C113.059 40.6975 110.023 37.6619 106.054 37.6619C102.084 37.6619 99.0488 40.6975 99.0488 44.667C99.0488 48.6365 102.318 51.6721 106.054 51.6721Z"
              fill="#FCFF83"
            />
            <path
              d="M620.927 133.398C626.764 133.398 631.434 128.728 631.434 122.89C631.434 117.053 626.764 112.383 620.927 112.383C615.089 112.383 610.419 117.053 610.419 122.89C610.419 128.728 615.322 133.398 620.927 133.398Z"
              fill="#FCFF83"
            />
            <path
              d="M151.587 82.0274C154.856 82.0274 157.424 79.4589 157.424 76.1899C157.424 72.9208 154.856 70.3523 151.587 70.3523C148.318 70.3523 145.749 72.9208 145.749 76.1899C145.749 79.4589 148.551 82.0274 151.587 82.0274Z"
              fill="#E97885"
            />
            <path
              d="M10.3176 441.621C15.4547 441.621 19.6577 437.418 19.6577 432.281C19.6577 427.144 15.4547 422.941 10.3176 422.941C5.18058 422.941 0.977539 427.144 0.977539 432.281C0.977539 437.418 5.18058 441.621 10.3176 441.621Z"
              fill="#E97885"
            />
          </svg>
        </div>
        <div
          className="uppercase text-9xl max-lg:text-8xl max-md:text-6xl h-full text-[#3F3F3F] font-bebas w-screen flex gap-6 items-center justify-center  leading-[90%] pt-6"
          data-aos="fade-up"
        >
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#784299]  via-[#D52127] to-[#F6851E]">
            About
          </span>
          US
        </div>
      </div>
      {/* {plane} */}
      <div
        className="absolute top-[60vh] md:top-[80vh]  -translate-y-1/2 -translate-x-full animate-plane"
      >
        <Plane />
      </div>
      <div
        className=" w-full flex flex-col p-6 md:p-12 lg:p-20 gap-8 md:gap-16"
        data-aos="fade-up"
      >
        <div className="flex flex-col">
          <span className="text-4xl md:text-6xl lg:text-9xl text-[#E83E4A] font-bebas uppercase">
            What makes us awesome?
          </span>
          
        </div>
        <div className="flex flex-col gap-2 md:gap-6">
        <span className="text-4xl md:text-6xl mt-4 lg:text-8xl text-black font-bebas uppercase">
            About us
          </span>
          <span className="text-xl md:text-3xl lg:text-4xl uppercase font-bebas leading-normal text-wrap">
            Kaun Devi Prasad? Yeah devi prasad ka ghar nahi hai, yeah “DV
            Social” hai aur hum ek creative content marketing agency hai.
          </span>
         
        </div>
        <div className="flex flex-col gap-2 md:gap-6">
          <span className="text-4xl md:text-6xl lg:text-8xl text-black font-bebas uppercase">
            Vision
          </span>
          
          <span className="text-xl md:text-3xl lg:text-4xl uppercase font-bebas leading-normal text-wrap">
            Our vision is to Transform brand's vision into reality, through
            out-of-the-box thinking and boundless creativity.
          </span>
        </div>
        <div className="flex flex-col gap-2 md:gap-6">
          <span className="text-4xl md:text-6xl lg:text-8xl text-black font-bebas uppercase">
            problem statement
          </span>
        
          <span className="text-xl md:text-3xl lg:text-4xl uppercase font-bebas leading-normal text-wrap">
            One of the biggest obstacles is the lack of resources, both in terms
            of time and money. Additionally, small businesses often have limited
            budgets, making it difficult to invest in the necessary tools and
            resources to create a strong online presence.
          </span>
        </div>
      </div>
    </div>
  );
};

export default PlanePage;
