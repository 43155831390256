import BuildYellow from "../assets/Group.png";
import builGreen from "../assets/greenBuild.png";
import board from "../assets/board.svg";

export  const HomeMob = () => {
    return (
      <div
      className="relative h-[70vh] md:h-screen w-screen  flex items-center justify-center overflow-hidden  transition-all duration-[5000ms]"
      id="home"
      >
        <div className=" bg-white absolute bottom-0 left-0 right-0 ">
          <img
            src={board}
            alt="Building"
            className="absolute hidden bottom-0 h-half left-0  z-[2]"
          />
          <img
            src={builGreen}
            alt="Building"
            className="absolute hidden bottom-0 left-0 z-[1] "
            onAnimationEnd={(event) => {
              event.currentTarget.parentElement.children[0].classList.remove(
                "hidden"
                );
                event.currentTarget.parentElement.children[0].classList.add(
                  "animate-downToTop"
                  );
            }}
          />
          <img
            src={BuildYellow}
            alt="Building"
            className="absolute bottom-0 left-0 animate-downToTop3"
            onAnimationEnd={(event) => {
              event.currentTarget.parentElement.children[1].classList.add(
                "animate-downToTop3"
                );
                event.currentTarget.parentElement.children[1].classList.remove(
                  "hidden"
                  );
                }}
                />
        </div>
      </div>
    );
  };