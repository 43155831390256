import React from "react";
import Carousel from "./carousel";

const Workflow = () => {
  const [scrollPosition, setScrollPosition] = React.useState(0);
  React.useEffect(()=>{
    var windowHeight = window.innerHeight;
    const check = () => {
      var elementTop = document.getElementById("work")?.offsetTop
      var elementHeight = document.getElementById("work")?.offsetHeight
      if(elementHeight!== null){
  
        if(0<windowHeight +window.pageYOffset -
          elementTop < elementHeight
          ) setScrollPosition((windowHeight +window.pageYOffset -elementTop)/windowHeight)
        }
    };

    window.addEventListener("scroll", check);

  return () => {
    window.removeEventListener("scroll", check);
  };
  
  },[])

  
  return (
    <div
      className="h-screen w-screen flex flex-col md:flex-row overflow-hidden  bg-black relative mt-12 "
      data-aos="fade-up"
    >
      <div className="h-[33vh] w-[33vh] absolute blur-[256px] rounded-full bg-[#CD2020] -left-[7vh] -top-[24vh] "></div>
      <div className="md:min-h-screen xl:min-w-half p-2 md:p-[5vh] pt-[10vh] flex flex-col lg:gap-4">
        {/* heading */}
        <div className="md:w-2/5 lg:w-1/2 min-h-[30vh] font-bold font-dm text-white leading-normal px-1">
          <span className="uppercase  text-6xl sm:text-7xl md:text-6xl lg:text-7xl  xl:text-8xl">
            OUR
          </span>
          <div className="relative pt-8 w-max">
            <span className="uppercase text-6xl sm:text-7xl md:text-6xl lg:text-7xl xl:text-8xl">
              Workflow
            </span>
            <div className="hidden sm:block">
              <span
                className="h-2 w-[88%] lg:h-4 xl:h-6 stroke-blue absolute top-11 left-0 opacity-50"
                // style={{ width: `${scrollPosition* 0.01 * 44}vw` }}
              >
                &nbsp;
              </span>
              <span className="w-[88%] h-1 lg:h-2 stroke-red absolute top-[72px] left-10 opacity-75"></span>
              <span className="w-[80%] h-1.5 stroke-black absolute top-24 left-10 "></span>
            </div>
          </div>
        </div>

        {/* quotes */}

        <div className="pt-12 lg:pt-16  text-white  font-dm pl-6 hidden md:block">
          
          <div className=" font-normal text-base xl:text-3xl ">
            {" "}
            "Boost your digital mojo with our killer content marketing tactics
            ! From social media sorcery  to branding brilliance  and
            analytical acumen , we'll whip up content so fresh and engaging,
            it'll make the internet drool . Together, we'll conjure an
            unstoppable online tribe  and pivot like a boss in this wild
            digital jungle . Ready to turbocharge your traffic , ignite
            engagement , and polish your biz's image to a dazzling shine ?
            Let's roll, amigo!"
          </div>
         
        </div>
      </div>
      <div className="h-5/6 md:h-full flex justify-center items-center">
        <Carousel />
      </div>
    </div>
  );
};

export default Workflow;
