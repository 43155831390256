import React, { useRef } from "react";
import JoinUs from "./joinUs";
import Job from "./job";

const Positions = () => {
  return (
    <div>
      <JoinUs />
      <div className="lg:h-max w-screen  flex flex-col lg:flex-row bg-black text-white py-8 lg:py-0">
        <div className="lg:text-8xl md:text-7xl text-4xl flex gap-4 lg:flex-col lg:w-1/2 md:h-full justify-center items-center font-dm font-bold leading-snug my-auto ">
          <span className="">OPEN</span>
          <span className="">POSITIONS</span>
        </div>
        <div className="flex-col space-y-16 no-scrollbar max-h-screen overflow-scroll flex lg:w-3/5 gap-8 lg:h-full lg:gap-0 lg:justify-between p-8  px-12">
          <Job
            title="Creative Content Writer/Creator"
            content="                Hey there! Do you have a knack for turning dull topics into
                captivating reads? Are you the mastermind behind that viral
                meme? If you think life is too short to be spent in a boring,
                formal job, then we've got the perfect role for you!"
            link="https://forms.gle/NnZxjM7dhM6xmHH48"
          />
          <Job
            title="Creative Copywriter"
            content="  Hey wordsmith! Are you tired of writing boring copy that nobody
                reads? Do you dream of crafting witty one-liners and compelling
                stories that make people go Wow!? If that's a resounding YES,
                then DV Social has got your back! "
            link="https://forms.gle/rGCUpJY9tt9au3467"
          />
          <Job
            title="Social Media Manager"
            content=" Once upon a time, in the vibrant world of DV Social, we were on
                the lookout for a social media wizard. Someone with the power to
                create captivating stories, breathe life into online
                communities, and lead our clients' digital kingdoms to glory.
                Does this magical journey excite you? Then keep reading!"
            link="https://forms.gle/RkM9Z2tyU81w8BA56"
          />
          <Job
            title=" Digital Marketer"
            content=" In the enchanted realm of DV Social, we are on an epic quest to discover a Digital Marketing Maestro. Someone who can orchestrate powerful online campaigns, enchant audiences with captivating content, and conjure up results that make our clients feel like they've struck gold. If this sounds like the symphony you've been waiting to compose, then DV Social is the stage for you!"
            link="https://forms.gle/8kByxrT3QfNrbuch6"
          />
          <Job
            title="Graphic Designer/Visualizer & Video Editor - The Visual Virtuoso"
            content="At DV Social, we're on the hunt for an artistic prodigy, a Visual Virtuoso who can make magic with their design and editing skills. A master of both still and moving images, with an innate ability to captivate audiences and evoke emotions through their visual storytelling. If this sounds like the creative adventure you've been dreaming of, then DV Social is the treasure trove of inspiration you've been seeking!"
            link="https://forms.gle/S4FH2ynVxfe6vqbH6"
          />{" "}
          <Job
            title="SEO Executive - The Search Sorcerer"
            content="Are you a mystical master of search engine optimization, a wizard who can summon the power of keywords and links to dominate the digital realm? If weaving your enchanting spells on Google's SERPs is your ultimate quest, then DV Social is seeking your talents as our very own Search Sorcerer!"
            link="https://forms.gle/tkUmQUuqjYo1T4fh9"
          />{" "}
          <Job
            title="Business Development Associate - The Sales Superhero:- "
            content="Do you possess the incredible power of persuasion, the uncanny ability to forge lasting connections, and the unparalleled agility to soar through the world of sales? If so, DV Social is searching for a Sales Superhero like you to join our league of extraordinary professionals!"
            link="https://forms.gle/baijjJQC2jQ3Et1k7"
          />{" "}
          <Job
            title="HR Maestro - The Talent Whisperer"
            content="Are you a master of deciphering the hidden potential within people, a true connoisseur of talent, and a wizard at matching the right individual to the perfect role? If so, DV Social is on the lookout for an HR Maestro like you to orchestrate the symphony of our growing team of creative geniuses!"
            link="https://forms.gle/XSWByf2ReoPyHPiT7"
          />
        </div>
      </div>
    </div>
  );
};

export default Positions;
