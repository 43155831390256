import React from "react";

const Hand = () => {
  return (
    <div>
      <svg
        width="530"
        height="311"
        viewBox="0 0 530 311"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-80 h-80 md:h-full md:w-full"
      >
        <g clip-path="url(#clip0_2_3415)">
          <mask
            id="mask0_2_3415"
            style={{maskType:"luminance"}}
            maskUnits="userSpaceOnUse"
            x="0"
            y="-613"
            width="530"
            height="924"
          >
            <path
              d="M529.99 -612.703H0.0078125V310.751H529.99V-612.703Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask0_2_3415)">
            <path
              d="M418.054 -60.2392C418.054 -60.2392 416.824 -64.1527 400.658 -96.4782C384.492 -128.804 338.696 -93.4461 349.807 -53.7132C356.879 -26.1021 372.37 4.54108 372.37 4.54108C364.147 4.80341 359.237 6.92279 355.9 9.92694C348.044 1.87616 339.576 -2.86609 330.612 -4.89099C333.243 -30.6626 331.41 -57.6758 325.45 -85.843C325.45 -85.843 332.942 -86.4201 341.007 -116.958C349.076 -147.497 368.665 -241.994 383.072 -322.089C397.478 -402.184 360.024 -448.278 354.259 -492.645C348.495 -537.012 346.191 -611.344 346.191 -611.344L108.201 -568.127C108.201 -568.127 117.997 -463.258 119.725 -422.346C121.453 -381.434 106.473 -353.778 88.6075 -307.106C70.7456 -260.435 21.1854 -93.3342 9.08393 -49.5411C-3.01756 -5.74792 -3.01756 8.65741 9.08393 30.5505C21.1854 52.4436 64.4045 124.47 88.6075 174.027C112.81 223.581 124.912 247.782 135.86 273.134C146.806 298.486 165.248 315.772 200.4 309.435C235.55 303.098 350.801 282.929 394.593 274.288C438.389 265.646 483.91 250.663 475.845 221.276C471.77 206.426 461.383 193.975 443.461 188.244C443.461 188.244 462.924 175.653 476.778 151.89C490.635 128.125 518.193 84.6676 525.457 70.322C532.724 55.976 529.654 42.0742 514.936 38.3669C500.215 34.6599 483.617 33.5443 475.739 35.6566C467.863 37.769 460.644 40.6193 460.644 40.6193C460.644 40.6193 471.091 -5.28619 468.685 -32.705C466.278 -60.1273 458.238 -69.8218 440.502 -68.4019C422.776 -66.9855 418.054 -60.2392 418.054 -60.2392Z"
              fill="white"
            />
            <path
              d="M346.904 -612.703C346.904 -612.703 349.209 -538.372 354.973 -494.004C360.737 -449.639 398.192 -403.541 383.786 -323.449C369.379 -243.358 349.786 -148.857 341.721 -118.318C333.652 -87.7799 326.164 -87.2028 326.164 -87.2028C332.124 -59.0356 333.956 -32.0224 331.326 -6.25077C340.29 -4.22934 348.758 0.516406 356.613 8.56719C359.95 5.56302 364.861 3.44015 373.084 3.18132C373.084 3.18132 357.592 -27.462 350.52 -55.0731C339.408 -94.8059 385.206 -130.164 401.371 -97.838C417.536 -65.5125 418.768 -61.599 418.768 -61.599"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M108.919 -569.488C108.919 -569.488 118.716 -464.619 120.444 -423.707C122.172 -382.795 107.192 -355.14 89.3263 -308.468C71.4608 -261.796 21.9042 -94.6956 9.80267 -50.9024C-2.29881 -7.10929 -2.29881 7.29619 9.80267 29.1891C21.9042 51.0858 65.1232 123.109 89.3263 172.666C113.529 222.218 125.631 246.421 136.578 271.772C147.525 297.124 165.968 314.411 201.118 308.074C223.401 304.056 277.865 294.483 325.914 285.811C333.525 284.436 341.362 285.902 348.497 284.6C355.434 283.335 361.68 279.299 367.88 278.145C378.565 276.161 387.95 274.381 395.312 272.927C439.108 264.285 484.629 249.302 476.563 219.914C468.498 190.526 435.719 170.529 368.702 193.429C327.369 191.992 282.156 199.491 248.369 212.427C248.369 212.427 244.683 196.892 237.996 182.703"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M234.672 80.5857C259.557 92.98 283.308 110.12 305.731 132.817C333.519 125.953 362.677 122.081 393.285 121.368C393.285 121.368 405.408 100.492 432.01 63.7876C458.612 27.0835 499.698 29.7764 516.87 37.5229C534.044 45.2694 533.372 57.391 520.575 79.9526C507.776 102.513 474.778 152.01 465.008 166.828C455.244 181.642 442.802 185.518 442.802 185.518"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M461.362 39.2592C461.362 39.2592 465.35 9.23841 468.379 -14.6691C471.407 -38.5766 468.715 -65.5163 451.205 -67.8735C433.697 -70.2307 405.408 -63.8341 395.64 -30.4978C385.875 2.83833 382.329 45.939 382.329 45.939L330.985 81.2966L267.676 45.939C267.676 45.939 254.703 50.2722 247.148 58.0467"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M266.979 45.9416C263.159 20.1106 251.555 -26.3125 251.555 -26.3125"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M431.996 -49.918C427.773 -19.0369 429.995 5.17838 442.215 13.6174C454.436 22.0598 464.295 16.9607 464.295 16.9607"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M504.646 53.3867C495.983 69.3832 489.317 80.2666 483.318 97.5954C477.32 114.926 492.216 122.594 498.32 115.982"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M330.986 81.3008C322.466 90.0442 321.133 113.189 321.133 113.189"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M382.333 45.9453C381.791 58.053 392.231 76.494 392.231 76.494"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M331.33 -6.25C321.809 -2.59888 299.813 16.2866 295.367 26.0615"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M357.694 7.66797C353.388 20.8109 355.99 34.9643 355.99 34.9643"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M373.086 3.17969C373.086 3.17969 380.312 10.9162 384.411 14.1479"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M305.733 132.82C296.255 147.582 297.588 168.245 297.588 168.245"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M393.289 121.371C394.678 133.363 400.012 146.47 404.454 153.137"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M368.702 193.43C365.125 205.12 365.125 220.449 365.125 220.449"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M460.22 230.668C443.558 236.222 408.234 249.106 400.898 258.216C393.564 267.326 397.702 272.451 397.702 272.451"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M248.372 212.426C241.602 223.782 241.602 243.109 241.602 243.109"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M236.271 185.52L243.739 196.306C243.739 196.306 243.19 169.327 248.247 140.426C253.304 111.52 268.481 106.463 268.481 106.463L233.516 81.3008L237.871 160.178L236.271 185.52Z"
              fill="#222222"
            />
            <path
              d="M231.639 67.7981C230.667 39.0183 238.133 17.0868 243.674 6.49345C249.214 -4.09978 254.512 8.56386 254.512 8.56386L257.933 2.95421C257.933 2.95421 261.26 -2.51907 256.682 -26.3111C252.543 -47.8231 270.825 -74.1017 289.957 -81.2572C298.592 -84.02 295.829 -91.5008 293.367 -94.2182C289.502 -98.3065 295.182 -111.782 307.989 -132.237C324.127 -158.009 334.246 -185.711 334.246 -185.711L295.574 -130.048L272.626 -93.127L237.871 -49.918L228.016 39.7422L231.639 67.7981Z"
              fill="#222222"
            />
            <path
              d="M416.912 -63.1073L408.489 -77.9219H405.069C405.069 -77.9219 411.175 -70.3502 401.648 -61.558C392.12 -52.7659 386.217 -48.8593 381.719 -53.7415C377.222 -58.6272 379.421 -48.8978 379.421 -48.8978C379.421 -48.8978 373.461 -47.1457 378.397 -42.5047C383.331 -37.8639 382.254 -17.8383 376.442 12.6931C370.628 43.2209 373.314 44.9312 358.659 55.1887C344.006 65.4463 375.269 53.3876 375.269 53.3876L381.09 45.9455L395.541 -34.933L416.912 -63.1073Z"
              fill="#222222"
            />
            <path
              d="M462.194 38.3319V18.4882L441.8 14.1445C441.8 14.1445 437.05 16.8304 441.842 19.5129C446.634 22.1918 443.661 31.0085 430.713 44.9278C417.768 58.8505 398.801 84.3499 392.04 97.2435C385.28 110.138 372.312 116.038 357.059 118.34C341.806 120.64 340.477 124.792 340.477 124.792L395.294 121.371C395.294 121.371 433.889 61.1447 434.134 61.2182C434.378 61.2915 453.43 42.9764 453.43 42.9764L462.194 38.3319Z"
              fill="#222222"
            />
            <path
              d="M444.079 187.271L462.194 170.952L474.54 150.531C474.54 150.531 475.019 143.211 470.252 149.465C465.485 155.721 454.162 168.507 423.632 172.904C393.103 177.3 314.453 190.733 314.453 190.733L361.838 193.43L411.91 184.14L444.079 187.271Z"
              fill="#222222"
            />
            <path
              d="M147.156 180.805C158.991 178.199 170.593 183.882 170.593 183.882"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M208.271 274.426C204.484 282.208 204.273 286.836 204.273 286.836"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M186.998 270.781C181.583 281.262 180.625 292.353 180.625 292.353"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M334.242 266.223C334.969 271.998 339.359 283.379 339.359 283.379"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M350.164 270.781L354.403 280.63"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M452.18 -0.675781C459.444 3.25198 464.302 1.98952 464.302 1.98952"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M496.367 103.523L500.687 107.057"
              stroke="#222222"
              stroke-width="10.4926"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_2_3415">
            <rect
              width="529.982"
              height="378.75"
              fill="white"
              transform="translate(0.0078125 -68)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Hand;
