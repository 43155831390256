import React from "react";

function Job({ title, content, link }) {
  return (
    <div>
      {" "}
      <div className="flex flex-col  mt-4  gap-6">
        <div className="">
          <svg
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M30.4798 22.1H28.9598V23.62H25.9098V26.67H22.8598V29.72H21.3398V31.24H31.9998V29.72H30.4798V22.1Z"
              fill="white"
            />
            <path
              d="M30.48 5.34003H28.96V8.38003H30.48V5.34003Z"
              fill="white"
            />
            <path d="M28.9597 20.57H27.4297V22.1H28.9597V20.57Z" fill="white" />
            <path d="M28.9597 8.38H27.4297V9.91H28.9597V8.38Z" fill="white" />
            <path d="M28.9597 3.81H27.4297V5.34H28.9597V3.81Z" fill="white" />
            <path
              d="M27.4302 9.91003H25.9102V11.43H27.4302V9.91003Z"
              fill="white"
            />
            <path
              d="M27.4302 2.29004H25.9102V3.81004H27.4302V2.29004Z"
              fill="white"
            />
            <path
              d="M25.9096 11.43H24.3896V12.96H25.9096V11.43Z"
              fill="white"
            />
            <path d="M25.9096 8.38H24.3896V9.91H25.9096V8.38Z" fill="white" />
            <path
              d="M25.9104 0.76001H22.8604V2.29001H25.9104V0.76001Z"
              fill="white"
            />
            <path
              d="M27.4304 19.05H22.8604V20.57H27.4304V19.05Z"
              fill="white"
            />
            <path
              d="M24.3904 12.96H22.8604V14.48H24.3904V12.96Z"
              fill="white"
            />
            <path
              d="M24.3904 6.85999H22.8604V8.37999H24.3904V6.85999Z"
              fill="white"
            />
            <path d="M22.8598 20.57H21.3398V22.1H22.8598V20.57Z" fill="white" />
            <path
              d="M22.8598 17.53H21.3398V19.05H22.8598V17.53Z"
              fill="white"
            />
            <path d="M22.8598 14.48H21.3398V16H22.8598V14.48Z" fill="white" />
            <path d="M22.8598 8.38H21.3398V9.91H22.8598V8.38Z" fill="white" />
            <path
              d="M22.8598 5.34003H21.3398V6.86003H22.8598V5.34003Z"
              fill="white"
            />
            <path
              d="M22.8598 2.29004H21.3398V3.81004H22.8598V2.29004Z"
              fill="white"
            />
            <path
              d="M21.3396 28.19H19.8096V29.72H21.3396V28.19Z"
              fill="white"
            />
            <path d="M21.3396 22.1H19.8096V23.62H21.3396V22.1Z" fill="white" />
            <path d="M21.3396 16H19.8096V17.53H21.3396V16Z" fill="white" />
            <path
              d="M21.3396 9.91003H19.8096V11.43H21.3396V9.91003Z"
              fill="white"
            />
            <path d="M21.3396 3.81H19.8096V5.34H21.3396V3.81Z" fill="white" />
            <path d="M19.81 23.62H18.29V28.19H19.81V23.62Z" fill="white" />
            <path d="M19.81 17.53H18.29V19.05H19.81V17.53Z" fill="white" />
            <path d="M19.81 11.43H18.29V12.96H19.81V11.43Z" fill="white" />
            <path
              d="M19.81 5.34003H18.29V6.86003H19.81V5.34003Z"
              fill="white"
            />
            <path d="M18.2895 22.1H16.7695V23.62H18.2895V22.1Z" fill="white" />
            <path
              d="M18.2895 19.05H16.7695V20.57H18.2895V19.05Z"
              fill="white"
            />
            <path
              d="M18.2895 12.96H16.7695V14.48H18.2895V12.96Z"
              fill="white"
            />
            <path
              d="M18.2895 6.85999H16.7695V8.37999H18.2895V6.85999Z"
              fill="white"
            />
            <path d="M16.7702 20.57H15.2402V22.1H16.7702V20.57Z" fill="white" />
            <path d="M16.7702 14.48H15.2402V16H16.7702V14.48Z" fill="white" />
            <path d="M16.7702 8.38H15.2402V9.91H16.7702V8.38Z" fill="white" />
            <path d="M15.2397 22.1H13.7197V23.62H15.2397V22.1Z" fill="white" />
            <path d="M15.2397 16H13.7197V17.53H15.2397V16Z" fill="white" />
            <path
              d="M15.2397 9.91003H13.7197V11.43H15.2397V9.91003Z"
              fill="white"
            />
            <path
              d="M13.7202 23.62H12.2002V25.15H13.7202V23.62Z"
              fill="white"
            />
            <path
              d="M13.7202 17.53H12.2002V19.05H13.7202V17.53Z"
              fill="white"
            />
            <path
              d="M13.7202 11.43H12.2002V12.96H13.7202V11.43Z"
              fill="white"
            />
            <path d="M13.7202 8.38H12.2002V9.91H13.7202V8.38Z" fill="white" />
            <path
              d="M12.1999 25.15H10.6699V26.67H12.1999V25.15Z"
              fill="white"
            />
            <path
              d="M12.1999 19.05H10.6699V20.57H12.1999V19.05Z"
              fill="white"
            />
            <path
              d="M12.1999 12.96H10.6699V14.48H12.1999V12.96Z"
              fill="white"
            />
            <path
              d="M12.1999 6.85999H10.6699V8.37999H12.1999V6.85999Z"
              fill="white"
            />
            <path
              d="M10.6704 26.67H9.15039V28.19H10.6704V26.67Z"
              fill="white"
            />
            <path d="M10.6704 20.57H9.15039V22.1H10.6704V20.57Z" fill="white" />
            <path d="M10.6704 14.48H9.15039V16H10.6704V14.48Z" fill="white" />
            <path
              d="M10.6704 5.34003H9.15039V6.86003H10.6704V5.34003Z"
              fill="white"
            />
            <path
              d="M9.15012 28.19H7.62012V29.72H9.15012V28.19Z"
              fill="white"
            />
            <path d="M9.15012 22.1H7.62012V23.62H9.15012V22.1Z" fill="white" />
            <path d="M9.15012 16H7.62012V17.53H9.15012V16Z" fill="white" />
            <path
              d="M9.15012 12.96H7.62012V14.48H9.15012V12.96Z"
              fill="white"
            />
            <path d="M9.15012 3.81H7.62012V5.34H9.15012V3.81Z" fill="white" />
            <path
              d="M7.61961 26.67H6.09961V28.19H7.61961V26.67Z"
              fill="white"
            />
            <path
              d="M7.61961 23.62H6.09961V25.15H7.61961V23.62Z"
              fill="white"
            />
            <path
              d="M7.61961 17.53H6.09961V19.05H7.61961V17.53Z"
              fill="white"
            />
            <path
              d="M7.61961 11.43H6.09961V12.96H7.61961V11.43Z"
              fill="white"
            />
            <path
              d="M7.61961 2.29004H6.09961V3.81004H7.61961V2.29004Z"
              fill="white"
            />
            <path
              d="M4.58 28.19H3.05V26.67H1.53V23.62H0V31.24H7.62V29.72H4.58V28.19Z"
              fill="white"
            />
            <path
              d="M6.10008 25.15H4.58008V26.67H6.10008V25.15Z"
              fill="white"
            />
            <path
              d="M6.10008 19.05H4.58008V20.57H6.10008V19.05Z"
              fill="white"
            />
            <path
              d="M6.10008 9.91003H4.58008V11.43H6.10008V9.91003Z"
              fill="white"
            />
            <path
              d="M6.0998 0.76001H3.0498V2.29001H6.0998V0.76001Z"
              fill="white"
            />
            <path d="M4.5798 23.62H3.0498V25.15H4.5798V23.62Z" fill="white" />
            <path d="M4.5798 20.57H3.0498V22.1H4.5798V20.57Z" fill="white" />
            <path d="M4.5798 8.38H3.0498V9.91H4.5798V8.38Z" fill="white" />
            <path d="M3.05027 22.1H1.53027V23.62H3.05027V22.1Z" fill="white" />
            <path
              d="M3.05027 6.85999H1.53027V8.37999H3.05027V6.85999Z"
              fill="white"
            />
            <path
              d="M3.05027 2.29004H1.53027V3.81004H3.05027V2.29004Z"
              fill="white"
            />
            <path d="M1.53 3.81H0V6.86H1.53V3.81Z" fill="white" />
          </svg>
        </div>
        <div className="font-dm text-3xl md:text-4xl">
          <span>{title}</span>
        </div>
        <div className="font-dm md:text-lg">
          <p>{content}</p>
        </div>
        <div className="flex gap-6 flex-wrap lg:flex-nowrap items-center xl:justify-start justify-center text-red-600 text-base font-dm font-bold">
          <div className="p-4 cursor-pointer bg-white w-max h-max ">
            3+ years of experience
          </div>
          <div className="p-4 cursor-pointer bg-white w-max h-max ">Remote</div>
          <div className="p-4 cursor-pointer bg-white w-max h-max ">
            Best in industry
          </div>{" "}
          {/* ==================== */}
        </div>
        {/* --------------------- */}{" "}
        {link && (
          <a
            href={link}
            target="_blank"
            className="flex gap-6 flex-wrap items-center justify-center xl:justify-start "
            rel="noreferrer"
          >
            <div className="p-4 cursor-pointer bg-red-600 text-white  w-max h-max ">
              Join Now
            </div>
          </a>
        )}
      </div>
    </div>
  );
}

export default Job;
