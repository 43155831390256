import React from "react";
import rectangle from "../assets/rectangle.png";
import circle3 from "../assets/circle3.png";
import "../assets/nav.css";
import radar from "../assets/radar.gif";
import circle2 from "../assets/circle2.png";

import { InView } from "react-intersection-observer";
import Aos from "aos";

function Rectangle() {
  Aos.init();

  let count = 0;
  const count_iteration = () => {
    if (count === 0) {
      count++;
      let valueDisplays = document.querySelectorAll(".num");
      valueDisplays.forEach((valueDisplay) => {
        let startValue = 0;
        let endValue = parseInt(valueDisplay.getAttribute("data-val"));
        let speed = 1000;
        let duration = Math.floor(5000 / endValue);
        if (endValue === 50) speed = 50;
        let counter = setInterval(function () {
          let interval = Math.ceil(endValue / speed);
          startValue += interval;
          valueDisplay.textContent = `${startValue}+`;

          if (startValue >= endValue) {
            clearInterval(counter);
          }
        }, duration);
      });
    }
  };

  return (
    <div>
      {/* {"destop view "} */}
      <InView
        as="div"
        onChange={(inView, entry) => {
          if (inView) {
            count_iteration();
            document
              .querySelector("#rec1")
              .classList.add("flip-horizontal-bottom");
            document
              .querySelector("#rec2")
              .classList.add("flip-horizontal-bottom");
            document
              .querySelector("#rec3")
              .classList.add("flip-horizontal-bottom");
          }
          // console.log("inView :", inView);
        }}
      >
        <div className=" mt-20 relative lg:block hidden" data-aos="fade-up">
          <div className=" w-screen font-jakarta text-5xl items-center justify-center font-medium  flex">
            {/* <p>
            We’re A Hardcore <span className="font-bold"> Creative Agency</span>
          </p> */}
            <div className="  text-2xl md:text-3xl flex justify-center font-bold text-center">
              <div className=" space-y-2">
                <div
                  className=""
                  id="rec1"
                  onAnimationEnd={(e) => {
                    e.target.classList.remove("flip-horizontal-bottom");
                  }}
                >
                  <span className="font-bold text-[#E83E4A]">
                    Innovation &nbsp;
                  </span>
                  is our baseline
                </div>
                <div
                  className=""
                  id="rec2"
                  onAnimationEnd={(e) => {
                    e.target.classList.remove("flip-horizontal-bottom");
                  }}
                >
                  <span className="font-bold text-[#E83E4A]">
                    Versatility&nbsp;
                  </span>
                  is our inception and &nbsp;
                </div>
                <div
                  className=""
                  id="rec3"
                  onAnimationEnd={(e) => {
                    e.target.classList.remove("flip-horizontal-bottom");
                  }}
                >
                  <span className="font-bold text-[#E83E4A]">
                    Revolutionary&nbsp;
                  </span>
                  means we're just getting started
                </div>
              </div>
            </div>
          </div>
          <div className="bg-black w-screen full flex justify-evenly my-32">
            {/* <div className="bg-black w-screen h-24 absolute "/> */}
            {/* img */}
            <img
              src={radar}
              alt=""
              className=" h-[20rem]  //animate-spin 
             flex items-center justify-center "
            />
            {/* <video  autoplay loop>
              <source src={radar} type="video/mp4" />
              <source src={radar} type="video/ogg" />
              Sorry, your browser doesn't support embedded videos.
            </video> */}
            {/* <div className="basis-2/5" /> */}

            <div className="flex flex-row capitalize text-white items-center justify-evenly space-x-12 ">
              {/* context */}
              {/* <div className="flex flex-col">
                <div
                  className="md:text-3xl text-bold text-xl font-[500] num"
                  data-val="5000"
                >
                  0+{" "}
                </div>
                <div className="text-sm">MEMES GENERATED</div>
              </div>
              <div className="flex flex-col">
                <div
                  className="md:text-3xl text-bold text-xl font-[500] num"
                  data-val="50"
                >
                  0+
                </div>
                <div className="text-sm">BRANDS COLLOBRATION</div>
              </div> */}
              <div className="flex flex-col">
                <div
                  className="md:text-3xl text-bold text-xl font-[500] num"
                  data-val="250"
                >
                  0+{" "}
                </div>
                <div className="text-sm ">Million Meme Collab</div>
              </div>
              <div className="flex flex-col">
                <div
                  className="md:text-3xl text-bold text-xl font-[500] num"
                  data-val="200"
                >
                  0+{" "}
                </div>
                <div className="text-sm "> Content Creator Network</div>
              </div>
              <div className="flex flex-col">
                <div
                  className="md:text-3xl text-bold text-xl font-[500] num"
                  data-val="100"
                >
                  0+{" "}
                </div>
                <div className="text-sm ">Successful Campaign</div>
              </div>
              <div className="flex flex-col">
                <div
                  className="md:text-3xl text-bold text-xl font-[500] num"
                  data-val="50"
                >
                  0+{" "}
                </div>
                <div className="text-sm ">Diverse Client Portfolio</div>
              </div>
            </div>
          </div>
        </div>
        {/* PHONE VIEW */}
        <div
          className="lg:hidden  h-max pb-4 w-screen  "
          data-aos="fade-up"
        >
          <div className=" bg-black ">
            <img src={radar} alt="" className="  mx-auto  h-[20rem] " />
            <div className="u uppercase text-white font-dm p-4 pt-12 text-start font-semibold text-2xl">
              Innovation is our baseline Versatality is our inception and
              Revolutionary means we're just getting started
            </div>
            <div className="flex flex-col space-y-8 px-4 mt-2">
              <div className="flex flex-col text-white font-dm  ">
                <div className="font-bold text-2xl num " data-val="250">
                  0+
                </div>
                <div className="font-normal uppercase text-sm">
                  Million Meme Collab
                </div>
              </div>
              <div className="flex flex-col text-white font-dm  ">
                <div className="font-bold text-2xl num" data-val="200">
                  0+
                </div>
                <div className="font-normal uppercase text-sm">
                  Content Creator Network
                </div>
              </div>
              <div className="flex flex-col text-white font-dm  ">
                <div className="font-bold text-2xl num" data-val="100">
                  0+
                </div>
                <div className="font-normal uppercase text-sm">
                  Successful Campaign
                </div>
              </div>
              <div className="flex flex-col text-white font-dm  ">
                <div className="font-bold text-2xl num" data-val="50">
                  0+
                </div>
                <div className="font-normal uppercase text-sm pb-12">
                  Diverse Client Portfolio
                </div>
              </div>
            </div>
            {/* <div>
              <div className="flex flex-col">
                <div
                  className="md:text-3xl text-bold text-xl font-[500] num"
                  data-val="250"
                >
                  0+{" "}
                </div>
                <div className="text-sm ">Million Meme Collab</div>
              </div>
              <div className="flex flex-col">
                <div
                  className="md:text-3xl text-bold text-xl font-[500] num"
                  data-val="200"
                >
                  0+{" "}
                </div>
                <div className="text-sm "> Content Creator Network</div>
              </div>
              <div className="flex flex-col">
                <div
                  className="md:text-3xl text-bold text-xl font-[500] num"
                  data-val="100"
                >
                  0+{" "}
                </div>
                <div className="text-sm ">Successful Campaign</div>
              </div>
              <div className="flex flex-col">
                <div
                  className="md:text-3xl text-bold text-xl font-[500] num"
                  data-val="50"
                >
                  0+{" "}
                </div>
                <div className="text-sm ">Diverse Client Portfolio</div>
              </div>
            </div> */}
          </div>
        </div>
      </InView>
    </div>
  );
}

export default Rectangle;
