import React from "react";

import footer from "../assets/footer.png";

const Footer = () => {
  return (
    <div className="bg-black w-screen text-white px-2 sm:px-12 md:px-24 md:pb-12 ">
      <div className="flex flex-col pt-16">
        <div className="flex flex-col gap-8 py-4 md:py-6 px-2">
          <div className="flex flex-col md:flex-row w-full justify-between items-start">
            <span className="font-bebas md:text-6xl lg:text-[140px] text-5xl">
              Lets Connect
            </span>
            <a className="rounded-xl text-xs sm:text-sm md:text-base lg:text-xl p-3 md:p-6 bg-[#E83E4A] my-2 h-10 text-white flex  items-center justify-center cursor-pointer uppercase" href="mailto:namaste@dvsocial.com">
                Mail us
              </a>
          </div>
          <div className="text-lg md:text-xl flex-none self-strech flex-grow-0 font-normal tracking-wider leading-[179%]">
          Insta pe milte hain! Let's connect, kyunki humse dosti karne ka nateeja sirf viral hota hai! 😉
          </div>
          <div className="flex  font-md items-center justify-between text-xs md:text-base">
            <div className="flex gap-2">
              <div className="flex-none flex items-center justify-center ">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.97942 2.21991C1.59787 2.60188 1.24802 3.07042 1.02182 3.47841C0.73771 3.97707 0.527973 4.52389 0.401523 5.09241L0.335719 5.43592C-0.701292 10.6972 0.615703 15.4038 4.96325 19.7204C7.81578 22.5526 9.97883 23.7164 12.9406 24.1413C13.4205 24.2102 13.9218 24.2619 14.5183 24.3061L15.7446 24.3806C16.429 24.416 17.5693 24.4728 17.9586 24.495C18.4713 24.5242 19.1496 24.4261 20.0612 24.2126L20.1993 24.1721C20.9951 23.891 21.7217 23.4349 22.3301 22.8258C24.5566 20.5969 24.5566 16.9831 22.3301 14.7542L22.1318 14.5648C19.9795 12.6049 16.6882 12.587 14.5158 14.5207L14.2582 14.7538L12.8817 16.1318C12.5801 16.4337 12.1051 16.4746 11.7564 16.2287C11.0309 15.717 9.10323 13.8304 8.63268 13.1874C8.37689 12.8378 8.41403 12.3537 8.72014 12.0474L9.93288 10.8341L10.0366 10.7497L10.1906 10.607C10.2184 10.5802 10.2467 10.5524 10.2748 10.5244C12.5655 8.23115 12.5655 4.51312 10.2748 2.21991C7.98407 -0.0733026 4.27012 -0.0733026 1.97942 2.21991ZM3.84006 4.08259C5.10315 2.81811 7.15103 2.81811 8.41413 4.08259C9.67722 5.34707 9.67722 7.3972 8.41413 8.66168C8.33598 8.73991 8.25458 8.81362 8.17014 8.88272L6.85998 10.1843C5.63541 11.4097 5.48685 13.346 6.51003 14.7442C7.15038 15.6192 9.28346 17.7069 10.2409 18.3822L10.4256 18.504C11.7998 19.3498 13.5882 19.1499 14.7424 17.9944L16.0149 16.7201L16.0323 16.7113L16.1549 16.5913C17.3559 15.4159 19.2803 15.4263 20.4695 16.6169C21.6684 17.8171 21.6684 19.7629 20.4695 20.9631L20.265 21.1505C20.0536 21.3276 19.822 21.4735 19.5753 21.5863L19.3762 21.6664L19.0379 21.7409L18.6807 21.8078C18.412 21.8528 18.2173 21.8712 18.1082 21.865L15.4364 21.7262L14.7286 21.6802L14.1146 21.6285C13.8268 21.6007 13.5647 21.5697 13.3138 21.5337C10.9428 21.1936 9.2858 20.302 6.81621 17.85C3.2278 14.2872 2.11555 10.6017 2.84874 6.31688L2.92544 5.90033C2.99495 5.4737 3.12368 5.10608 3.3151 4.77002C3.43164 4.55999 3.63432 4.28856 3.84006 4.08259Z"
                    fill="#A0ABBB"
                  />
                </svg>
              </div>
              <div className="flex flex-col gap-1">
                <span className="">Tel</span>
                <span className=" font-medium text-blue-500 hover:underline">
                  +91 7241116222
                </span>
              </div>
            </div>
            <div className="flex gap-2 px-2">
              <div className="flex-none flex items-center justify-center">
                <svg
                  width="28"
                  height="23"
                  viewBox="0 0 28 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.99805 0.832031C2.78891 0.832031 0.998047 2.62289 0.998047 4.83203V18.1654C0.998047 20.3745 2.78891 22.1654 4.99805 22.1654H23.6647C25.8739 22.1654 27.6647 20.3745 27.6647 18.1654V4.83203C27.6647 2.62289 25.8739 0.832031 23.6647 0.832031H4.99805ZM4.7654 3.51892C4.84095 3.50563 4.91869 3.4987 4.99805 3.4987H23.6647C23.7441 3.4987 23.8218 3.50563 23.8974 3.51893L14.3314 9.89626L4.7654 3.51892ZM3.66471 5.99007V18.1654C3.66471 18.9017 4.26167 19.4987 4.99805 19.4987H23.6647C24.4011 19.4987 24.998 18.9017 24.998 18.1654V5.99009L15.071 12.6081C14.6231 12.9067 14.0397 12.9067 13.5918 12.6081L3.66471 5.99007Z"
                    fill="#A0ABBB"
                  />
                </svg>
              </div>
              <div className="flex flex-col gap-1">
                <span className="">Mail</span>
                <span
                  href="mailto:xyz@gmail.com"
                  className="font-medium cursor-pointer text-blue-500 hover:underline"
                >
                  namaste@dvsocial.com
                </span>
              </div>
            </div>
            <div className="flex gap-2">
              <div className="flex-none flex items-center justify-center">
                <svg
                  width="19"
                  height="27"
                  viewBox="0 0 19 27"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.66341 2.83464C5.98151 2.83464 2.99674 5.8194 2.99674 9.5013C2.99674 13.2185 5.14567 18.6577 9.66341 23.575C14.1812 18.6577 16.3301 13.2185 16.3301 9.5013C16.3301 5.8194 13.3453 2.83464 9.66341 2.83464ZM0.330078 9.5013C0.330078 4.34664 4.50875 0.167969 9.66341 0.167969C14.8181 0.167969 18.9967 4.34664 18.9967 9.5013C18.9967 14.3709 16.1108 20.9395 10.6062 26.4441C10.0855 26.9648 9.2413 26.9648 8.7206 26.4441C3.216 20.9395 0.330078 14.3709 0.330078 9.5013Z"
                    fill="#A0ABBB"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.66341 10.8346C10.3998 10.8346 10.9967 10.2377 10.9967 9.5013C10.9967 8.76492 10.3998 8.16797 9.66341 8.16797C8.92703 8.16797 8.33008 8.76492 8.33008 9.5013C8.33008 10.2377 8.92703 10.8346 9.66341 10.8346ZM9.66341 13.5013C11.8726 13.5013 13.6634 11.7104 13.6634 9.5013C13.6634 7.29216 11.8726 5.5013 9.66341 5.5013C7.45427 5.5013 5.66341 7.29216 5.66341 9.5013C5.66341 11.7104 7.45427 13.5013 9.66341 13.5013Z"
                    fill="#A0ABBB"
                  />
                </svg>
              </div>
              <div className="flex flex-col gap-1">
                <span className="">Address</span>
                <span className="font-medium text-blue-500 hover:underline cursor-pointer">
                  Your Address
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center flex-col lg:flex-row  justify-between pt-6 px-2 border-t border-footer-border ">
          <div className="font-dm text-center flex  justify-center items-center space-x-2 sm:gap-10 gap-2 pb-4 sm:text-base text-xs">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.linkedin.com/company/dv-social/"
              className="cursor-pointer underline text-blue-500"
            >
              Linkedin
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.facebook.com/dvsocial"
              className="cursor-pointer underline text-blue-500"
            >
              Facebook
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.instagram.com/dvsocialofficial/"
              className="cursor-pointer underline text-blue-500"
            >
              Instagram
            </a>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.behance.net/vindhyeagnihot"
              className="cursor-pointer underline text-blue-500"
            >
              Behance
            </a>
          </div>
          <div className="font-dm text-base hidden md:block">
            <span>© 2000-2021, All Rights Reserved</span>
          </div>
        </div>
      </div>
    </div>
  );
  // return(
  //   <div className="h-half md:h-screen w-screen pt-16 bg-white">
  //     <img src={footer} className="h-1/2 md:h-full w-full " alt="footer" />
  //   </div>
  // )
};

export default Footer;
