import React from "react";
import Hand from "./hand";
import Landing from "./landing";
import Navbar from "./navbar";
// import Home from "./home";
import Rectangle from "./Rectangle";
import CardPage from "./cards-page";
import Workflow from "./workflow";
import Quote from "./quote";
import Footer from "./footer";
import { HomeMob } from "./mobHome";
import { gsap, ScrollTrigger as scrollTrigger } from "gsap/all";
import Quote2 from "./quote2";
gsap.registerPlugin(scrollTrigger);

const Main = () => {
  // const main = React.useRef();
  setTimeout(() => {
        document.querySelector("#main").classList.add("animate-screen");
          document.getElementById("home").classList.remove("hidden");
  }, 5000);
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    // setWidth(window.innerWidth);

    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <div className="overflow-x-hidden">
      <div
        className={`flex flex-col w-screen items-center justify-center -translate-y-[320px] md:-translate-y-[311px] z-20 fixed`}
        onAnimationEnd={(event) => {
          event.currentTarget.classList.add("hidden");
          event.currentTarget.classList.remove("overflow-hidden");

          // window.scrollTo(0, 30);
        }}
        onClick={(event) => {
          event.currentTarget.classList.add("animate-screen");
          document.getElementById("home").classList.remove("hidden");
        }}

        id="main"
      >
        <div className="">
          <Hand />
        </div>
        <Landing />
      </div>
      <div className="hidden" id="home">
        <Navbar />
        <HomeMob />
        <Rectangle />
        <CardPage />
        <Workflow />
        {/* <Quote /> */}
        <Quote2 />
        {/* <Footer /> */}
      </div>
    </div>
  );
};

export default Main;
