import React from 'react'
import Navbar from './navbar';

function ContactUs() {
  return (
    <div>
      <Navbar />
      <div className="py-32 w-screen bg-[#141414] flex flex-col outline-none items-center justify-center ">
        <div
          className="uppercase text-9xl max-lg:text-8xl max-md:text-6xl text-[#3F3F3F] font-bebas w-screen flex gap-6 items-center justify-center  leading-[90%] pt-6"
          data-aos="fade-left"
        >
          <span className="bg-clip-text text-transparent bg-gradient-to-r from-[#784299]  via-[#D52127] to-[#F6851E]">
            Contact{" "}
          </span>
          US
        </div>
        <div className="flex items-center justify-center  w-screen p-16">
          <form
            className="flex flex-col outline-none text-white justify-center items-center gap-28 max-lg:gap-24 max-md:gap-16"
            data-aos="fade-right"
          >
            <div className="grid grid-cols-2 grid-flow-row gap-24 gap-x-48 max-md:flex-col max-md:flex max-md:gap-6">
              <label className="flex flex-col outline-none text-[#999999]  w-96 max-lg:w-60  text-base ">
                Hi there! What's your First Name?
                <input
                  className="bg-[#141414] border-b-[1px]  border-[#CCCCCC] pt-4 overflow-x-scroll"
                  type="text"
                  placeholder="Jot down your cool first name here"
                  name = "entry.743909164"
                />
              </label>
              <label className="flex flex-col outline-none text-[#999999]  w-96 max-lg:w-60  text-base">
                Now, what's your Last Name?
                <input
                  className="bg-[#141414] border-b-[1px]  border-[#CCCCCC] pt-4 overflow-x-scroll"
                  type="text"
                  placeholder="Share your awesome last name with us"
                  name = "entry.743909164"
                />
              </label>
              <label className="flex flex-col outline-none text-[#999999]  w-96 max-lg:w-60  text-base">
                We'd love to stay connected! What's your Email ID?
                <input
                  className="bg-[#141414] border-b-[1px]  border-[#CCCCCC] pt-4 overflow-x-scroll"
                  type="email"
                  placeholder="Drop your email address here (we'll treat it like gold!)"
                  name = "entry.743909164"
                />
              </label>
              <label className="flex flex-col outline-none text-[#999999]  w-96 max-lg:w-60  text-base">
                What's the best Phone Number to reach you at?
                <input
                  className="bg-[#141414] border-b-[1px]  border-[#CCCCCC] pt-4 overflow-x-scroll"
                  type="number"
                  placeholder="Type your digits here (we won't call at odd hours!)"
                  name = "entry.743909164"
                />
              </label>
              <label className="flex flex-col outline-none text-[#999999]  w-96 max-lg:w-60  text-base">
                Got a secret Alternate Name? We're all ears! (Optional)
                <input
                  className="bg-[#141414] border-b-[1px]  border-[#CCCCCC] pt-4 overflow-x-scroll"
                  type="text"
                  placeholder="Whisper your alias here"
                  name = "entry.743909164"
                />
              </label>
              <label className="flex flex-col outline-none text-[#999999]  w-96 max-lg:w-60  text-base">
                Let's talk! What's the Subject of your message?
                <input
                  className="bg-[#141414] border-b-[1px]  border-[#CCCCCC] pt-4 overflow-x-scroll"
                  type="text"
                  placeholder="Share your thoughts, ideas, or queries here"
                  name = "entry.743909164"
                />
              </label>
            </div>
            <div className='w-72 bg-[#E83E4A] h-16 rounded-full text-2xl flex justify-center items-center font-bold max-lg:w-64 max-md:w-48 max-md:text-lg max-md:h-12'>
              <a
                type="submit"
                className=""
                href='https://forms.gle/tngKBiyB17oTQQk28'
                target="_blank"
              rel="noreferrer"
              >
                🎉 Click to Submit! 🎉
              </a>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUs