// import logo from './logo.svg';
import "./App.css";
import React from "react";
import {Routes , Route } from "react-router-dom";
import About from "./components/aboutus";
import Main from "./components/Main";
import PlanePage from './components/planePage'

import JoinUs from "./components/joinUs";
import Navbar from "./components/navbar";
import 'aos/dist/aos.css';
import Aos from 'aos';
import Positions from "./components/positions";
import ContactUs from "./components/contactUs";
import Footer from "./components/footer";

import ScrollToTop from "./scrollToTop";


function App() {
  
  React.useEffect(()=>{
    Aos.init({
      duration: 2000
    });
    Aos.refresh()
  },[])

  // window.onbeforeunload = function () {
  //   window.scrollTo(0, 0);
  // }
  
  return (
    <>
        <ScrollToTop />
      <Routes>
        <Route path="/" element={<Main />} />
        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/join" element={<JoinUs />}/> */}
        <Route path="/contact" element={<ContactUs/> } />
        <Route path="/join" element={<Positions />} />
        <Route path="/about" element={<PlanePage />} />
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
