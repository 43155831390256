import React from "react";
import logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import "../assets/nav.css";
function Navbar() {
  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    const handleWindowResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  const MyComponent = () => {
    const breakpoint = 650;

    return width < breakpoint ? <MobileComponent /> : <DesktopComponent />;
  };

  const DesktopComponent = () => {
    return (
      <div
        data-aos="fade-down"
        // data-aos-duration="3000"
        className="flex flex-row items-start p-0  h-[75px] w-[90vw] top-[28px] fixed rounded-2xl z-10 drop-shadow-[0px_0px_4px_rgba(0, 0, 0, 0.25)] left-[5vw] bg-[#222222] items-center space-x-6"
      >
        <div className="navbar-scroll-img ">
          <Link to="/">
            <img src={logo} alt="dv Social logo " className="" />
          </Link>
        </div>
        <div className="w-full">
          <div className="flex">
            <div className="flex  space-x-4  lg:space-x-16">
              {/* <div className="nav-links">COLLECTION</div> */}
              {/* <div className="nav-links">ROADMAP</div>
              <div className="nav-links">TEAM </div> */}
              {/* <Link to="/">
                <div className="nav-links">ABOUT US</div>
              </Link> */}

              <div className="nav-links">
                <Link to="/about"> About Us</Link>
              </div>

              <div className="nav-links">
                <Link to="/contact">Contact Us</Link>
              </div>
              <div className="nav-links">
                {" "}
                <Link to="/join">Career</Link>
              </div>
              {/* <div className="nav-links">
                <Link to="/about">About</Link>{" "}
              </div> */}
            </div>{" "}
            <div className="sd p-3 w-[150px] lg:w-[200px] h-full rounded-xl bg-[#E83E4A] text-white flex ml-auto justify-center cursor-pointer items-end mr-6">
              <Link to="/contact"> Register with us </Link>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const MobileComponent = () => {
    const [clickOpen, setClickOpen] = React.useState(false);

    return (
      <div
        className="flex flex-col gap-2 w-[90vw] top-[28px] fixed left-[5vw] z-10"
        data-aos="fade-down"
      >
        <div className="flex flex-row items-center justify-between px-4  h-[75px] rounded-2xl  drop-shadow-[0px_0px_4px_rgba(0, 0, 0, 0.25)] left-[5vw] bg-[#222222] items-center space-x-6 ">
          <div className="navbar-scroll-img ">
            <img src={logo} alt="dv Social logo " className="" />
          </div>
          <div className="">
            <div
              className="h-12 w-12 rounded-full bg-[#3F3F3F] flex flex-col items-center cursor-pointer justify-center gap-1"
              onClick={(event) => setClickOpen(!clickOpen)}
            >
              <span className="w-6 h-[2px] bg-white rounded-xl">&nbsp;</span>
              <span className="w-4 h-[2px] bg-white rounded-xl">&nbsp;</span>
              <span className="w-2 h-[2px] bg-white rounded-xl">&nbsp;</span>
            </div>
          </div>
        </div>
        {clickOpen && (
          <div className="flex flex-col bg-[#222222] rounded-lg p-5 text-sm text-white items-center justify-around gap-2">
            <Link to="/">
                <div className="">Home</div>
              </Link>

            <Link to="/about">
              <div className="">About Us </div>
            </Link>

            <Link to="/contact">
              <div className="">Contact Us </div>
            </Link>
            <Link to="/join">
                <div className="">Career </div>
            </Link>
            {/* <Link to="/about">
              <div className="">About </div>
            </Link> */}
            <Link to="/contact">
              <div className="rounded-xl p-3 bg-[#E83E4A] h-10 text-white flex  items-center justify-center cursor-pointer uppercase">
                Register with us
              </div>
            </Link>
          </div>
        )}
      </div>
    );
  };
  return <MyComponent />;
}

export default Navbar;
