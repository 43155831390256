import React from "react";
import LeftJoinus from "../assets/LeftJoinus.png";
import RightJoinus from "../assets/RightJoinus.png";
import Navbar from "./navbar";
function JoinUs() {

  return (
    <div>
      <div className="container ">
        <Navbar />
        <div
          className="h-screen w-screen flex flex-col items-center justify-center overflow-hidden "
          data-aos="fade-up"
        >
          <div>
            <div className="font-gobold text-5xl md:text-6xl lg:text-8xl whitespace-nowrap m-4 flex gap-12 animate-rightToLeft delay-1000  drop-shadow-xl txt-shadow  ">
              HUMKO JOIN KARLO BOHUT SCOPE HAI * HUMKO JOIN KARLO BOHUT SCOPE
              HAI * HUMKO JOIN KARLO BOHUT SCOPE HAI * HUMKO JOIN KARLO BOHUT
              SCOPE HAI * HUMKO JOIN KARLO BOHUT SCOPE HAI * HUMKO JOIN KARLO
              BOHUT SCOPE HAI * HUMKO JOIN KARLO BOHUT SCOPE HAI * HUMKO JOIN
              KARLO BOHUT SCOPE HAI * HUMKO JOIN KARLO BOHUT SCOPE HAI * HUMKO
              JOIN KARLO BOHUT SCOPE HAI * HUMKO JOIN KARLO BOHUT SCOPE HAI *
              HUMKO JOIN KARLO BOHUT SCOPE HAI * HUMKO JOIN KARLO BOHUT SCOPE
              HAI * HUMKO JOIN KARLO BOHUT SCOPE HAI * HUMKO JOIN KARLO BOHUT
              SCOPE HAI * HUMKO JOIN KARLO BOHUT SCOPE HAI *
            </div>
          </div>

          <div className="font-dm  text-xl md:text-2xl font-normal md:w-3/4 px-8 md:px-0  lg:w-1/2 text-center opacity-60 mt-16  md:mt-12 ">
            One service for all of your creative needs. Quality standards.
            Painless management. Unlimited requests & revisions.
          </div>
          <div className=" absolute left-0 bottom-0">
            <img
              src={LeftJoinus}
              alt=""
              className="w-[46vw] md:w-[40vw] lg:w-[30vw]"
            />
          </div>
          <div className=" absolute right-0 bottom-0">
            <img
              src={RightJoinus}
              alt=""
              className="w-[46vw] md:w-[40vw] lg:w-[30vw]"
            />
          </div>
          <div
            className="bg-black relative cursor-pointer mt-20  scale-75 md:scale-100"

          >
            <div className="font-dm text-lg font-semibold px-10 py-4 text-white">
              I'M ALREADY INTERESTED
            </div>
            <div className="h-3 w-3 bg-[#FEEE95] absolute top-3" />
            <div className="h-3 w-3 bg-[#B9E6FE] absolute top-0" />
            <div className="h-3 w-3 bg-[#FECDD6] absolute top-0 left-3" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default JoinUs;
