import React from "react";

const Quote2 = () => {
  return (
    <div className="w-screen h-[68vh] bg-card-green flex items-center justify-center px-2 md:pl-[90px] md:pr-[90px]">
      <span className="font-bebas text-white font-normal text-5xl sm:text-6xl md:text-7xl lg:text-8xl leading-[91%] text-center">
        "Our vision is to Transform brand's vision into reality, through
        out-of-the-box thinking and boundless creativity."
      </span>
    </div>
  );
};

export default Quote2;
