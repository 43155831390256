import React from "react";
import work1 from "../assets/first1.jpg";
import work2 from "../assets/first2.jpg";
import work3 from "../assets/first3.png";
import work4 from "../assets/first4.png";
import work5 from "../assets/first5.png";
import work6 from "../assets/first6.png";
import work7 from "../assets/first7.png";
import work8 from "../assets/second1.png";
import work9 from "../assets/second2.png";
import work10 from "../assets/second3.png";
import work11 from "../assets/second4.png";
import work12 from "../assets/second5.jpg";
import work13 from "../assets/second6.jpg";
import work14 from "../assets/second7.jpg";
import work15 from "../assets/second8.jpg";
import work16 from "../assets/second9.jpg";
import work17 from "../assets/second10.jpg";
import work18 from "../assets/second11.jpg";
import work19 from "../assets/second12.jpeg";
import work20 from "../assets/second13.jpg";
import work21 from "../assets/second14.jpg";
import work22 from "../assets/second15.jpg";
import work23 from "../assets/third1.jpg";
import work24 from "../assets/third2.jpg";
import work25 from "../assets/third3.jpg";
import work26 from "../assets/third4.jpg";
import work27 from "../assets/third5.jpg";
import work28 from "../assets/third6.jpg";
import work29 from "../assets/third7.jpg";
import work30 from "../assets/third8.jpg";
import work31 from "../assets/third9.jpg";
import work36 from "../assets/third10.jpg";
import work33 from "../assets/third11.jpg";
// import work33 from "../assets/second.jpg";


const Carousel = () => {
  return (
    <div className="h-full flex items-center justify-center overflow-hidden">
    <div className="flex  gap-2 md:gap-4 lg:gap-6 h-5/6 items-center  md:p-8  ">
    <div className="flex flex-col gap-12 md:pt-12 animate-scroll1 h-[calc(160px*12)] ">
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work1}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work2}></img>
        </div>
        <div className="border-t-4 border-[#747474] flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl overflow-hidden">
          <img alt="" className="h-full w-full " src={work3}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work5}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work6}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work7}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work1}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work5}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work6}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work7}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work3}></img>
        </div>
      </div>
      <div className="flex flex-col-reverse gap-12 animate-scroll2 h-[calc(160px*12)]">
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work8}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work9}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work10}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work11}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work12}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work13}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work14}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work15}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work16}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work17}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work18}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work19}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work20}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work21}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work22}></img>
        </div>
      </div>
      
      <div className="flex flex-col gap-12 animate-scroll1 h-[calc(160px*12)]">
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work23}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work24}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work25}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work26}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work27}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work28}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work29}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work30}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work31}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work36}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work33}></img>
        </div>
        <div className=" flex-none h-40 w-36  md:h-[22.5vh] md:w-36 rounded-2xl">
          <img alt="" className="h-full w-full " src={work23}></img>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Carousel;
